import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceToolFieldTrackingDirective, ServiceToolMetadata } from '@big-direkt/service-tools/base';
import { ButtonGroupDataModel, UiButtonGroupComponent } from '@big-direkt/ui/button-group';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { UiInputComponent } from '@big-direkt/ui/input';
import { TranslocoDirective } from '@jsverse/transloco';
import { InsurantGroup } from '../../models/advantage-calculator-form.model';

@Component({
    selector: 'big-service-tool-advantage-calculator-form',
    templateUrl: './advantage-calculator-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        ServiceToolFieldTrackingDirective,
        TranslocoDirective,
        UiButtonGroupComponent,
        UiFormRowComponent,
        UiInputComponent,
    ],
})
export class AdvantageCalculatorFormComponent {
    @Input({ required: true }) public group!: FormGroup<InsurantGroup>;
    @Input({ required: true }) public metadata!: ServiceToolMetadata;
    @Input({ required: true }) public insurantIndex!: number;
    @Input() public hasBeenSubmitted = false;
    @Input() public insurantType: 'primary' | 'additional' = 'primary';

    public nameErrorOverrides = {
        required: 'stAdvantageCalculator.error.nameRequired',
        pattern: 'stAdvantageCalculator.error.namePattern',
    };

    public genderOptions: ButtonGroupDataModel<string>[] = [
        { label: 'stAdvantageCalculator.label.male', value: 'm' },
        { label: 'stAdvantageCalculator.label.female', value: 'f' },
        { label: 'stAdvantageCalculator.label.diverse', value: 'd' },
    ];

    public genderErrorOverrides = {
        required: 'stAdvantageCalculator.error.genderRequired',
    };

    public ageErrorOverrides = {
        required: 'stAdvantageCalculator.error.ageRequired',
        pattern: 'stAdvantageCalculator.error.agePattern',
    };
}
