<ng-container
    *transloco="let t; prefix: 'stAdvantageCalculator'"
    [formGroup]="form">
    <div class="mb-10">
        <button
            type="button"
            class="inline-flex items-center font-bold text-primary hover:text-primary-light"
            (click)="onBackBtnClick($event)">
            <big-icon
                [icon]="backIcon"
                [size]="'w-4'"
                class="mr-2 fill-primary"></big-icon>
            {{ isInitialState() || (!editFormIsShown() && !isInitialState()) ? t('label.backToYourData') : t('label.backToOverview') }}
        </button>
    </div>

    @if (editFormIsShown()) {
        <div class="mb-6">
            <big-service-tool-advantage-calculator-form
                [group]="form.controls.additionalInsurants.at(index())"
                [metadata]="metadata"
                [insurantIndex]="index() + 2"
                [insurantType]="'additional'"
                [hasBeenSubmitted]="hasBeenSubmitted()" />

            <div class="mt-10">
                <big-ui-button
                    [buttonStyle]="'primary'"
                    (clicked)="onSubmit($event)"
                    [type]="'submit'">
                    {{ t(isEditMode() ? 'label.additionalInsurants.applyChanges' : 'label.additionalInsurants.submit') }}
                </big-ui-button>
            </div>
        </div>
    } @else {
        <h3>{{ t('additionalInsurants.title') }}</h3>
        <p [innerHTML]="t('additionalInsurants.description')"></p>

        <h3>{{ t('addedPerson', { count: form.controls.additionalInsurants.controls.length }) }}</h3>

        @if (isInitialState() && form.controls.additionalInsurants.controls.length === 0) {
            <p>{{ t('additionalInsurants.singleInsurantState') }}</p>
        } @else {
            <ul>
                @for (group of form.controls.additionalInsurants.controls; let index = $index; track $index) {
                    @if (group.controls.name?.value && group.controls.gender.value && group.controls.age.value) {
                        <li class="flex gap-4">
                            <strong class="flex-1 break-all">
                                {{ group.controls.name?.value }}, {{ t('label.selectedGender', { gender: group.controls.gender.value }) }},
                                {{ t('years', { years: group.controls.age.value }) }}
                            </strong>
                            <button
                                (click)="onDelete(index)"
                                role="button"
                                type="button"
                                class="translate-y-[1px]">
                                <big-icon
                                    [icon]="deleteIcon"
                                    [size]="'w-5'"
                                    [title]="t('label.delete', { name: group.controls.name?.value })"
                                    class="appearance-none fill-primary hover:fill-primary-light"></big-icon>
                            </button>
                            <button
                                (click)="onEdit(index)"
                                role="button"
                                type="button"
                                class="translate-y-[1px]">
                                <big-icon
                                    [icon]="editIcon"
                                    [size]="'w-5'"
                                    [title]="t('label.edit', { name: group.controls.name?.value })"
                                    class="appearance-none fill-primary hover:fill-primary-light"></big-icon>
                            </button>
                        </li>
                    }
                }
            </ul>
        }

        <div class="mt-6">
            <big-ui-button
                [buttonStyle]="'primary'"
                class="mt-10"
                [type]="'button'"
                (clicked)="onAddInsurantClick($event)">
                <span class="flex items-center">
                    <big-icon
                        [icon]="iconPlus"
                        [size]="'w-4'"
                        class="mr-2 fill-white"></big-icon>
                    <span class="translate-y-[1px]">{{ t('label.addInsurant') }}</span>
                </span>
            </big-ui-button>
        </div>

        <div class="mt-20 text-center">
            <big-ui-button
                [buttonStyle]="'secondary'"
                class="mt-10"
                [type]="'submit'"
                (clicked)="onGoToSummaryClick($event)">
                {{ t('label.submit') }}
            </big-ui-button>
        </div>
    }
</ng-container>
