<ng-container *transloco="let t; prefix: 'stAdvantageCalculator'">
    @if (isLoading()) {
        <big-ui-spinner />
    } @else if (!hasDataLoadingError()) {
        <div class="mb-12 sm:mb-14 lg:mb-10">
            <h2 class="break-long-words">{{ t('title.submitHeader') }}</h2>
        </div>
        <ng-container [formGroup]="form">
            <!-- Primary Insurant -->
            <fieldset class="last-of-type:mb-16 print:break-inside-auto">
                <legend>
                    <h3>{{ t('title.forPrimaryInsurant') }}</h3>
                </legend>
                <ng-container [formGroupName]="'primaryInsurant'">
                    <ng-container [formArrayName]="'advantages'">
                        @for (advantageGroup of form.controls.primaryInsurant.controls.advantages.controls; let index = $index; track $index) {
                            <big-ui-info-checkbox
                                [id]="'primaryInsurant-' + advantageGroup.controls.advantage.value?.title"
                                [defaultValue]="true"
                                [title]="advantageGroup.controls.advantage.value?.title"
                                [valueLabel]="(advantageGroup.controls.advantage.value?.value | currency: 'EUR' : 'symbol' : '1.0-0') ?? undefined"
                                [infoText]="advantageGroup.controls.advantage.value?.description"
                                [href]="advantageGroup.controls.advantage.value?.href"
                                [formControl]="advantageGroup.controls.checked"
                                class="mb-2 last-of-type:mb-6"
                                (infoLayerOpened)="onInfoLayerOpened(advantageGroup.controls.advantage.value!.title, 1)"
                                (infoLayerLinkClicked)="onInfoLayerLinkClicked($event, advantageGroup.controls.advantage.value!.title, 1)" />
                        }
                    </ng-container>
                    @if (form.controls.additionalInsurants.length > 0) {
                        <h4 class="w-full mb-6 text-gray-100 text-end dark:text-white">
                            {{ form.controls.primaryInsurant.controls.sum.value | currency: 'EUR' : 'symbol' : '1.0-0' }} *
                        </h4>
                    }
                </ng-container>
            </fieldset>
            <!-- Additional Insurants -->
            <ng-container [formArrayName]="'additionalInsurants'">
                @for (additionalInsurant of form.controls.additionalInsurants.controls; let index = $index; track $index) {
                    <fieldset class="last-of-type:mb-16 print:break-inside-auto">
                        <legend>
                            <h3 class="break-all">{{ t('title.forAdditionalInsurants', { name: additionalInsurant.controls.name.value }) }}</h3>
                        </legend>
                        <ng-container [formGroupName]="index">
                            <ng-container [formArrayName]="'advantages'">
                                @for (advantageGroup of additionalInsurant.controls.advantages.controls; track $index) {
                                    <big-ui-info-checkbox
                                        [id]="'additionalInsurant-' + index + '-' + advantageGroup.controls.advantage.value?.title"
                                        [defaultValue]="true"
                                        [title]="advantageGroup.controls.advantage.value?.title"
                                        [valueLabel]="(advantageGroup.controls.advantage.value?.value | currency: 'EUR' : 'symbol' : '1.0-0') ?? undefined"
                                        [infoText]="advantageGroup.controls.advantage.value?.description"
                                        [href]="advantageGroup.controls.advantage.value?.href"
                                        [formControl]="advantageGroup.controls.checked"
                                        class="mb-2 last-of-type:mb-6"
                                        (infoLayerOpened)="onInfoLayerOpened(advantageGroup.controls.advantage.value!.title, index + 2)"
                                        (infoLayerLinkClicked)="onInfoLayerLinkClicked($event, advantageGroup.controls.advantage.value!.title, index + 2)" />
                                }
                            </ng-container>

                            <h4 class="w-full mb-6 text-gray-100 text-end dark:text-white">
                                {{ additionalInsurant.controls.sum.value | currency: 'EUR' : 'symbol' : '1.0-0' }} *
                            </h4>
                        </ng-container>
                    </fieldset>
                }
            </ng-container>
            <div class="flex justify-between mb-20">
                <h3>{{ t('title.totalAdvantages') }}</h3>
                <h3 class="text-gray-100 text-end dark:text-white">
                    {{ t('title.totalAdvantagesSum', { sum: totalSum | currency: 'EUR' : 'symbol' : '1.0-0' }) }} *
                </h3>
            </div>
        </ng-container>

        <h2 class="mb-16">{{ t('title.advantagesForAllMembers') }}</h2>
        <h3>{{ t('title.bigServices') }}</h3>
        <div class="print:break-inside-auto">
            @for (bigService of resultData.bigServices; track $index) {
                <big-ui-info-checkbox
                    [id]="bigService.title"
                    [mode]="'icon'"
                    [title]="bigService.title"
                    [valueLabel]="t('label.inclusive')"
                    [infoText]="bigService.description"
                    [href]="bigService.href"
                    class="mb-2 last-of-type:mb-8"
                    (infoLayerOpened)="onInfoLayerOpened(bigService.title)"
                    (infoLayerLinkClicked)="onInfoLayerLinkClicked($event, bigService.title)" />
            }
        </div>
        <h3>{{ t('title.digitalServices') }}</h3>
        <div class="print:break-inside-auto">
            @for (digitalService of resultData.digitalServices; track $index) {
                <big-ui-info-checkbox
                    [id]="digitalService.title"
                    [mode]="'icon'"
                    [title]="digitalService.title"
                    [valueLabel]="t('label.inclusive')"
                    [infoText]="digitalService.description"
                    [href]="digitalService.href"
                    class="mb-2 last-of-type:mb-20"
                    (infoLayerOpened)="onInfoLayerOpened(digitalService.title)"
                    (infoLayerLinkClicked)="onInfoLayerLinkClicked($event, digitalService.title)" />
            }
        </div>

        <div class="flex items-center justify-between mb-10 print:hidden">
            <big-ui-button
                [id]="'recalculateAdvantages'"
                [buttonStyle]="'link'"
                class="font-semibold group text-primary hover:text-primary-light"
                [classList]="['no-underline']"
                [type]="'button'"
                (clicked)="recalculateAdvantages(t('label.recalculateAdvantages'))">
                <div class="flex items-center space-x-2">
                    <span class="w-4">
                        <big-icon
                            [icon]="iconArrowLeft"
                            [size]="'w-4'"
                            class="fill-primary group-hover:fill-primary-light"></big-icon>
                    </span>
                    <span>
                        {{ t('label.recalculateAdvantages') }}
                    </span>
                </div>
            </big-ui-button>

            <big-ui-link
                [href]="resultData.becomeMemberHref"
                [bigUiButtonStyle]="'secondary'"
                [linkClass]="'text-center no-underline hover:text-primary'"
                (linkClicked)="trackResultActionLink(t('label.applyForMembership'), $event)">
                {{ t('label.applyForMembership') }}
            </big-ui-link>
        </div>

        <div class="mb-8 print:break-inside-auto">
            {{ t('disclaimer', { number: servicePhoneNumber.title }) }}
        </div>

        <div class="flex justify-between">
            <div>{{ t('label.calculationDate', { date: calculationDate | date: 'dd.LL.yyyy HH:mm' }) }}</div>
            <big-ui-button
                [id]="'print'"
                [buttonStyle]="'icon'"
                [ariaLabel]="t('label.print')"
                class="group"
                (clicked)="printAdvantages(t('label.print'))">
                <big-icon
                    [icon]="iconPrint"
                    [size]="'w-8'"
                    class="fill-primary group-hover:fill-primary-light"></big-icon>
            </big-ui-button>
        </div>
    } @else {
        <big-ui-alert
            [title]="t('error.title')"
            [classList]="'flex text-center border-2 rounded-md flex-col p-4! mt-10'"
            type="danger">
            {{ t('error.unavailable', { number: errorPhoneNumber.title }) }}
        </big-ui-alert>
    }
</ng-container>
