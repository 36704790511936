import { NgClass } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, signal } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { UiInfoButtonComponent } from '@big-direkt/ui/info-button';
import { UiLinkModule } from '@big-direkt/ui/link';
import { IconBigMediumOk, IconBigMediumOkKreis, IconBigMediumPfeilRechts, IconComponent } from '@big-direkt/utils/icons';
import { BaseControlValueAccessor } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'big-ui-info-checkbox',
    templateUrl: './info-checkbox.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IconComponent, NgClass, ReactiveFormsModule, TranslocoDirective, UiInfoButtonComponent, UiLinkModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InfoCheckboxComponent,
            multi: true,
        },
    ],
})
export class InfoCheckboxComponent extends BaseControlValueAccessor<boolean> implements AfterViewInit {
    public readonly iconArrowRight = IconBigMediumPfeilRechts;
    public readonly iconCheck = IconBigMediumOk;
    public readonly iconCheckCircle = IconBigMediumOkKreis;

    @HostBinding('class') @Input() public classList = 'block';

    @Input({ required: true }) public id!: string;
    @Input() public mode: 'check' | 'icon' = 'check';
    @Input() public defaultValue = false;
    @Input() public title?: string;
    @Input() public valueLabel?: string;
    @Input() public infoText?: string;
    @Input() public href?: string;
    @Output() public readonly infoLayerOpened = new EventEmitter<boolean>();
    @Output() public readonly infoLayerLinkClicked = new EventEmitter<{ linkType: 'external_link' | 'internal_link'; linkLabel: string }>();

    public isInfoTextOpen = signal<boolean>(false);

    public ngAfterViewInit(): void {
        this.writeValue(this.defaultValue);
        this.onChange(this.defaultValue);
    }

    public onInfoLayerOpened(open: boolean): void {
        this.isInfoTextOpen.set(open);
        this.infoLayerOpened.emit(open);
    }

    public onInfoLayerLinkClicked(linkType: 'external_link' | 'internal_link', linkLabel: string): void {
        this.infoLayerLinkClicked.emit({ linkType, linkLabel });
    }
}
