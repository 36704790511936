import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceToolMetadata } from '@big-direkt/service-tools/base';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { IconBigBoldPlus, IconComponent } from '@big-direkt/utils/icons';
import { TranslocoDirective } from '@jsverse/transloco';
import { AdvantageCalculatorForm } from '../../models/advantage-calculator-form.model';
import { AdvantageCalculatorService } from '../../services/advantage-calculator.service';
import { AdvantageCalculatorFormComponent } from '../advantage-calculator-form/advantage-calculator-form.component';

@Component({
    selector: 'big-service-tool-advantage-calculator-primary-insurant-step',
    templateUrl: './primary-insurant-step.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AdvantageCalculatorFormComponent, IconComponent, FormsModule, ReactiveFormsModule, TranslocoDirective, UiButtonComponent],
})
export class AdvantageCalculatorPrimaryInsurantStepComponent implements OnInit {
    private readonly advantageCalculatorService = inject(AdvantageCalculatorService);

    public readonly iconPlus = IconBigBoldPlus;

    @Input({ required: true }) public metadata!: ServiceToolMetadata;
    @Input() public hasBeenSubmitted = false;
    @Output() public readonly submitted = new EventEmitter<Event>();
    @Output() public readonly summaryClicked = new EventEmitter<Event>();

    public form!: FormGroup<AdvantageCalculatorForm>;

    public ngOnInit(): void {
        this.form = this.advantageCalculatorService.getForm;
    }

    public emitEvent(eventType: 'submitted' | 'summary', $event: Event): void {
        $event.preventDefault();
        this.hasBeenSubmitted = true;

        if (eventType === 'submitted') {
            this.submitted.emit();
        }

        if (eventType === 'summary') {
            this.summaryClicked.emit();
        }
    }
}
