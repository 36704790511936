import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceToolMetadata } from '@big-direkt/service-tools/base';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { IconBigBoldPlus, IconBigMediumBearbeiten, IconBigMediumPfeilLinks, IconBigMediumSchliessenKreis, IconComponent } from '@big-direkt/utils/icons';
import { TranslocoDirective } from '@jsverse/transloco';
import { AdvantageCalculatorForm } from '../../models/advantage-calculator-form.model';
import { AdvantageCalculatorService } from '../../services/advantage-calculator.service';
import { AdvantageCalculatorFormComponent } from '../advantage-calculator-form/advantage-calculator-form.component';

@Component({
    selector: 'big-service-tool-advantage-calculator-additional-insurants-step',
    templateUrl: './additional-insurants-step.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AdvantageCalculatorFormComponent, IconComponent, FormsModule, ReactiveFormsModule, TranslocoDirective, UiButtonComponent],
})
export class AdvantageCalculatorAdditionalInsurantsStepComponent implements OnInit {
    private readonly advantageCalculatorService = inject(AdvantageCalculatorService);

    public readonly backIcon = IconBigMediumPfeilLinks;
    public readonly deleteIcon = IconBigMediumSchliessenKreis;
    public readonly editIcon = IconBigMediumBearbeiten;
    public readonly iconPlus = IconBigBoldPlus;

    @Input({ required: true }) public metadata!: ServiceToolMetadata;
    @Output() public readonly backClicked = new EventEmitter();
    @Output() public readonly summaryClicked = new EventEmitter();

    public form!: FormGroup<AdvantageCalculatorForm>;
    public index = signal(0);
    public hasBeenSubmitted = signal(false);
    public editFormIsShown = signal(false);
    public isEditMode = signal(false);
    public isInitialState = signal(true);

    public ngOnInit(): void {
        this.form = this.advantageCalculatorService.getForm;

        // Show form only on start if we have no additional insurant
        if (this.form.controls.additionalInsurants.controls.length === 0) {
            this.advantageCalculatorService.addAdditionalInsurant();
            this.editFormIsShown.set(true);
        }
    }

    public onBackBtnClick($event: Event): void {
        $event.preventDefault();

        if (this.editFormIsShown() && !this.isInitialState()) {
            if (!this.hasBeenSubmitted() && !this.form.controls.additionalInsurants.at(this.index()).valid) {
                this.advantageCalculatorService.deleteAdditionalInsurant(this.index());
            }

            this.hideEditForm();

            return;
        }

        this.backClicked.emit();
    }

    public onSubmit($event: Event): void {
        $event.preventDefault();

        this.hasBeenSubmitted.set(true);

        if (this.form.controls.additionalInsurants.at(this.index()).valid) {
            this.hasBeenSubmitted.set(false);
            this.editFormIsShown.set(false);
            this.isInitialState.set(false);
            this.isEditMode.set(false);
        }
    }

    public onEdit(index: number): void {
        this.isEditMode.set(true);
        this.showEditForm(index);
    }

    public onDelete(index: number): void {
        this.advantageCalculatorService.deleteAdditionalInsurant(index);

        if (this.form.controls.additionalInsurants.length === 0) {
            this.isInitialState.set(true);
        }
    }

    public onAddInsurantClick($event: Event): void {
        $event.preventDefault();

        this.advantageCalculatorService.addAdditionalInsurant();
        this.showEditForm();
    }

    public onGoToSummaryClick($event: Event): void {
        $event.preventDefault();

        this.summaryClicked.emit();
    }

    private showEditForm(index?: number): void {
        this.index.set(index ?? this.form.controls.additionalInsurants.length - 1);
        this.editFormIsShown.set(true);
    }

    private hideEditForm(): void {
        this.index.set(0);
        this.editFormIsShown.set(false);
    }
}
